const login = {
    title: 'Login / Registration',
    titleBonus: 'Login to make orders and receive bonuses',
    content: {
        termsOfUse: {
            text: 'Continuing, I agree with',
            link: 'the privacy policy',
        },
        phoneNumber: {
            article: 'Login or register to place orders, receive bonuses and more',
        },
        smsCode: {
            smsText: 'Enter the SMS code received on the specified number',
            phoneCallText: 'Enter the last {{codeLength}} digits of the caller number',
            emailText: 'Enter the code received on the specified address',
            codeDidNotCome: 'Have not received the code?',
            resendCode: 'Send it again',
            retryCall: 'Request a repeat call',
            timeLeft: ' after {{value}} sec',
        },
        email: {
            text: 'Enter your email address',
        },
    },
    form: {
        fields: {
            username: {
                placeholder: 'example@mail.com',
                label: 'E-mail',
                validations: {
                    require: 'this field is required',
                    email: 'You specified an invalid email address',
                },
            },
            password: {
                label: 'Password',
                validations: {
                    require: 'this field is required',
                },
            },
            rememberMe: {
                label: 'Remember me',
            },
            phoneNumber: {
                label: 'Phone',
                validations: {
                    phone: 'You specified an invalid phone number',
                    require: 'This field is required',
                },
            },
            email: {
                label: 'email',
            },
            firstName: {
                label: 'First and last name',
            },
        },
    },
    links: {
        registration: 'Registration',
        recoveryPassword: 'I forgot my password :(',
    },
    profileButton: 'My profile',
    loginButton: 'Login / Registration',
    logoutButton: 'Logout',
    submitButton: {
        next: 'Next',
        confirm: 'Confirm',
    },
    error: {
        phoneNumber: 'Phone error',
        code: 'Invalid confirmation code, try again or request a new one',
    },
};

export default login;
