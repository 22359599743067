import { message } from 'antd';
import Axios from 'axios';
import { batch } from 'react-redux';

import { AddressesActions } from './addresses';
import { CartActions } from './cart';
import { PartnerActions } from './partner';
import { parseError } from '../../aqua-delivery-client-graphql/utils';

import { getAppConfig } from '../../utils/appConfig';

import NetworkStatus from '../../utils/enums/NetworkStatus';
import { StorageKeys } from '../../utils/enums/StorageKeys';
import { refreshToken } from '../../utils/httpClient';
import TokenHelper from '../../utils/token';
import tokenHelper from '../../utils/token';
import { TAction } from '../store';

export const Types = {
    SET_USER: 'USER@SET:USER',
    SET_NETWORK_STATUS: 'USER@SET:NETWORK:STATUS',
};

export type TSetUserAction = {
    type: typeof Types.SET_USER;
    payload: any;
};

export type TSetNetworkStatusAction = {
    type: typeof Types.SET_NETWORK_STATUS;
    payload: NetworkStatus;
};

export type TUser = TSetUserAction | TSetNetworkStatusAction;

type TRegUser = {
    email: string | null;
    phoneNumber: string | null;
    firstName: string;
};

export type TUpdateUserData = {
    username: string;
    phoneNumber?: string;
    email: string;
};

type UserType = {
    fetchUser: (token?: string) => TAction<Promise<void>>;
    fetchProfile: () => TAction<Promise<void>>;
    register: (values: TRegUser) => TAction<Promise<void>>;
    logoutUser: () => TAction<Promise<void>>;
    deleteAccount: () => TAction<Promise<void>>;
    assignUser: (phone: string, fio: string) => TAction<Promise<void>>;
    setUser: (data: TUserData | TUserAnonData | null) => TSetUserAction;
    setNetworkStatus: (status: NetworkStatus) => TSetNetworkStatusAction;
    update: (data: TUpdateUserData) => TAction<Promise<void>>;
};

export type TUserAnonData = {
    uuid?: string;
    email: string | null;
    username: string;
    phone_number: string;
};

export type TUserData = {
    id: number;
    external_id: string;
    uuid: string;
    username: string;
    comment: string | null;
    email: string;
    status: number;
    created_at: string;
    updated_at: string;
    phone_number: string;
    phone: string;
    raw_phone: string | null;
    contract_number: number;
    type: number;
    legal_address: string | null;
    user_source: string;
    date_last_use_mobile_app: string;
    notify_make_order_date: string;
    invite_code: string;
    loyalty_system: number;
    city_id: number;
    do_not_call: number;
    after_purchase_registration_date: string | null;
    password_reset_date_for_aggregator: string | null;
    last_phone_call_date: string | null;
    chat_settings: string;
    address: string;
    coins: number;
    show_order_statuses: boolean;
    about_invitation_process_url: string;
    invite_code_qr_uri: string;
    text_for_invite_code: string;
    roles: {
        guest: {
            type: number;
            name: string;
            description: string | null;
            ruleName: string | null;
            data: string | null;
            createdAt: string | null;
            updatedAt: string | null;
        };
        customer: {
            type: string;
            name: string;
            description: string;
            ruleName: null;
            data: null;
            createdAt: null;
            updatedAt: null;
        };
    };
    share_app_uri: string;
    show_do_not_call_check_mark: boolean;
    need_to_reset_password: boolean;
    mercure_jwt: string;
    mercure_topic: string;
};

const userCancelToken = Axios.CancelToken.source();

const applicationId = getAppConfig().applicationId;

const errorHandler = (error: any, defaultErrorMessage: string) => {
    if (Axios.isCancel(error)) {
        return;
    }

    if (error.response && error.response.status !== 404) {
        const parseMessage = parseError(error);
        const errorMessage = parseMessage ?? defaultErrorMessage;
        message.error(errorMessage);
    }
};

export const UserActions: UserType = {
    fetchUser(token?: string) {
        return async (dispatch, _, { api, httpClientServices }) => {
            dispatch(this.setNetworkStatus(NetworkStatus.loading));

            return httpClientServices
                .getClient()
                .post(
                    api.updateUserDataByJWTToken,
                    {},
                    {
                        headers: {
                            ordersource: 'site',
                            Authorization: `Bearer ${token || TokenHelper.getToken()}`,
                        },
                        cancelToken: userCancelToken.token,
                    },
                )
                .then(({ data }) => {
                    if (data.success) {
                        return batch(async () => {
                            await dispatch(this.fetchProfile()).then(() => {
                                dispatch(this.setNetworkStatus(NetworkStatus.ready));
                            });
                            await dispatch(AddressesActions.fetchAddresses());
                            if (localStorage.getItem('anonUser')) {
                                localStorage.removeItem('anonUser');
                            }
                        });
                    } else {
                        dispatch(this.logoutUser());
                        message.error(data.message || 'Failed to fetch user');
                    }
                })
                .catch(e => {
                    dispatch(this.setNetworkStatus(NetworkStatus.ready));
                    errorHandler(e, 'Failed to fetch user');
                });
        };
    },
    fetchProfile() {
        return async (dispatch, _, { api, httpClientServices }) => {
            httpClientServices
                .getClient()
                .get<TUserData>(api.getProfile)
                .then(({ data }) => {
                    dispatch(this.setUser(data));
                })
                .catch(e => {
                    throw errorHandler(e, 'Failed to fetch profile') as any;
                });
        };
    },
    assignUser(phone, fio) {
        return async (_, getState, { httpClientServices, services }) => {
            const { info } = getState().cart;
            const cart = info?.cart;
            if (cart) {
                const data = {
                    cart,
                    phoneNumber: phone,
                    name: fio,
                };

                httpClientServices
                    .getClient('cart')
                    .post(services.cart.assignUser, data)
                    .catch(e => errorHandler(e, 'Failed to assign user'));
            }
        };
    },
    register(values) {
        return async (dispatch, _, { httpClientServices, services }) => {
            try {
                await httpClientServices
                    .getClient('auth')
                    .post(services.auth.completeRegistration, values, {
                        headers: { Application: applicationId },
                    });

                await refreshToken();
                const token = await dispatch(PartnerActions.partnerEnrichToken());

                if (token) {
                    await dispatch(this.fetchUser(token));
                } else {
                    await dispatch(this.fetchUser());
                }
                if (localStorage.getItem('anonUser')) {
                    localStorage.removeItem('anonUser');
                }
            } catch (e) {
                throw errorHandler(e, 'Failed to register') as any;
            }
        };
    },
    update(data) {
        return async (_, __, { httpClientServices }) => {
            const url = 'v1/auth/update';

            return httpClientServices
                .getClient()
                .post(url, data)
                .then(({ data }) => {
                    if (data?.success === false) {
                        for (const field in data.errors) {
                            data.errors[field].forEach((error: string) => {
                                message.error(error);
                            });
                        }
                    }
                });
        };
    },
    logoutUser() {
        return async dispatch => {
            batch(() => {
                dispatch(this.setUser(null));
                dispatch(CartActions.clearCart());
                dispatch(AddressesActions.clearAddresses());
            });
            TokenHelper.cleanTokens();
            localStorage.removeItem(StorageKeys.deprecatedSelectedAddress);
            localStorage.removeItem(StorageKeys.selectedAddress);
            localStorage.removeItem(StorageKeys.deviceId);
        };
    },
    deleteAccount() {
        return async (dispatch, _, { api, httpClientServices }) => {
            const response = await httpClientServices
                .getClient()
                .delete(api.deleteAccount)
                .catch(e => {
                    throw errorHandler(e, 'Failed to delete account') as any;
                });

            if (response.status === 200 && response.data.success) {
                batch(() => {
                    tokenHelper.cleanTokens();
                    localStorage.removeItem(StorageKeys.selectedAddress);
                    localStorage.removeItem(StorageKeys.selectedAddress);
                    localStorage.removeItem(StorageKeys.deprecatedSelectedAddress);
                    dispatch(CartActions.clearCart());
                    dispatch(AddressesActions.clearAddresses());
                    window.location.reload();
                });
            }
        };
    },
    setUser(data) {
        return {
            type: Types.SET_USER,
            payload: data,
        };
    },
    setNetworkStatus(status) {
        return {
            type: Types.SET_NETWORK_STATUS,
            payload: status,
        };
    },
};
