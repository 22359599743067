const login = {
    title: 'Вход / Регистрация',
    titleBonus: 'Авторизуйтесь, чтобы делать заказы и получать бонусы',
    content: {
        termsOfUse: {
            text: 'Продолжая, я согласен с',
            link: 'политикой конфиденциальности',
        },
        phoneNumber: {
            article:
                'Войдите или зарегистрируйтесь, чтобы делать заказы, получать бонусы и многое другое',
        },
        smsCode: {
            smsText: 'Введите SMS-код, полученный на указанный номер',
            phoneCallText: 'Введите последние {{codeLength}} цифры звонившего номера',
            emailText: 'Введите код, полученный на указанный адрес',
            codeDidNotCome: 'Код не пришел?',
            resendCode: 'Отправить повторно',
            retryCall: 'Запросить повторый звонок',
            timeLeft: ' через {{value}} сек',
        },
        emailCode: {
            text: 'Введите код, полученный на указанную почту',
            codeDidNotCome: 'Код не пришел? Отправить повторно через {{value}} сек',
        },
        email: {
            text: 'Введите email',
        },
    },
    form: {
        fields: {
            username: {
                placeholder: 'example@mail.com',
                label: 'E-mail',
                validations: {
                    require: 'Поле обязательно для заполнения',
                    email: 'Вы указали невалидный E-mail',
                },
            },
            password: {
                label: 'Пароль',
                validations: {
                    require: 'Поле обязательно для заполнения',
                },
            },
            rememberMe: {
                label: 'Запомнить меня',
            },
            phoneNumber: {
                label: 'Телефон',
                validations: {
                    phone: 'Вы указали невалидный номер телефона',
                    require: 'Поле обязательно для заполнения',
                },
            },
            email: {
                label: 'email',
            },
            firstName: {
                label: 'Имя и фамилия',
            },
        },
    },
    links: {
        registration: 'Регистрация',
        recoveryPassword: 'Я забыл пароль :(',
    },
    profileButton: 'Кабинет',
    loginButton: 'Вход / Регистрация',
    logoutButton: 'Выход',
    submitButton: {
        next: 'Далее',
        confirm: 'Подтвердить',
    },
    error: {
        phoneNumber: 'Ошибка номера телефона',
        code: 'Неверный код подтверждения, попробуйте снова или запросите новый',
    },
};

export default login;
