import JwtDecode from 'jwt-decode';

import { StorageKeys } from './enums/StorageKeys';
import { TokenPayload, UserRoles, ServicesIdentity } from '../types';

enum Tokens {
    token = 'token',
    refresh = 'refreshToken',
}
class TokenHelper {
    private static instance: TokenHelper;

    private token: string | null = null;

    private refreshToken: string | null = null;

    constructor() {
        if (typeof TokenHelper.instance === 'object') {
            return TokenHelper.instance;
        }

        this.restoreTokens();
        TokenHelper.instance = this;
        return this;
    }

    public restoreTokens() {
        const servicesIdentity = localStorage.getItem(StorageKeys.servicesIdentity);
        if (servicesIdentity) {
            const { token, refreshToken }: ServicesIdentity = JSON.parse(servicesIdentity);
            this.token = token;
            this.refreshToken = refreshToken;
        } else {
            this.token = localStorage.getItem(Tokens.token);
            this.refreshToken = localStorage.getItem(Tokens.refresh);
        }
    }

    public isAnon() {
        const token = this.getToken();

        return !!token && !this.isAnonToken(this.decodeToken(token).roles);
    }

    public isAnonToken(roles: UserRoles[]) {
        const anonTypes = [UserRoles.AnonymousAggregatorClient, UserRoles.RoleClientApp];

        return anonTypes.some(type => roles.includes(type));
    }

    public decodeToken(token: string): TokenPayload {
        return JwtDecode<TokenPayload>(token);
    }

    public hasPartner() {
        const token = this.getToken();
        if (token) {
            return !!this.decodeToken(token).partner_id;
        }

        return false;
    }

    public getToken() {
        return this.token;
    }

    public getRefreshToken() {
        return this.refreshToken;
    }

    public needRefresh() {
        const token = this.getToken();
        if (token) {
            const exp = this.decodeToken(token).exp;
            if (exp - +new Date() > 0) {
                return true;
            }
        }

        return false;
    }

    setTokens(tokensObj: { token: string; refresh?: string }) {
        this.token = tokensObj.token;
        if (tokensObj.refresh) {
            this.refreshToken = tokensObj.refresh;
        }
        this.saveTokens(tokensObj);
    }

    private saveTokens(tokensObj: { token: string; refresh?: string }) {
        localStorage.setItem(
            StorageKeys.servicesIdentity,
            JSON.stringify({
                token: tokensObj.token,
                refreshToken: tokensObj.refresh,
            }),
        );
    }

    public cleanTokens() {
        this.token = null;
        this.refreshToken = null;
        localStorage.removeItem(Tokens.token);
        localStorage.removeItem(Tokens.refresh);
        localStorage.removeItem(StorageKeys.servicesIdentity);
    }
}

const tokenHelper = new TokenHelper();

export default tokenHelper;
