import React from 'react';

import { lazy } from '@loadable/component';
import * as Sentry from '@sentry/react';
import { ConfigProvider as AntdConfigProvider } from 'antd';
import { createBrowserHistory } from 'history';
import TagManager from 'react-gtm-module';
import { Provider as ReduxProvider } from 'react-redux';
import { Router, Switch, Route } from 'react-router-dom';
import { useUrlSearchParams } from 'use-url-search-params';
import 'url-search-params-polyfill';

import EmptyPanel from './aqua-delivery-web-client-ui/components/EmptyPanel';
import Loader from './aqua-delivery-web-client-ui/components/loader/Loader';
import LoaderContainer from './aqua-delivery-web-client-ui/components/loader/LoaderContainer';
import SuspenseWithLoader from './aqua-delivery-web-client-ui/components/loader/SuspenseWithLoader';
import useDocumentLanguage from './aqua-delivery-web-client-ui/hooks/useDocumentLanguage';
import I18n, { I18nSetConfig, I18nContext } from './aqua-delivery-web-client-ui/i18n';
import Localization from './aqua-delivery-web-client-ui/localization/Localization';
import localizationConfig from './aqua-delivery-web-client-ui/localization/localizationConfig';
import Seo from './components/Seo/Seo';
import YandexMetrika from './components/YandexMetrika/YandexMetrika';
import { config } from './config';
import useAntdLocale from './hooks/useAntdLocale';
import translations from './translations';
import { getAppConfig } from './utils/appConfig';
import TokenHelper from './utils/token';

import 'swiper/swiper.min.css';
import 'swiper/modules/pagination/pagination.min.css';
import 'swiper/modules/free-mode/free-mode.less';
import 'swiper/modules/navigation/navigation.less';

import './aqua-delivery-web-client-ui/assets/styles/common.less';

const isSentrySendingEnabled = process.env.NODE_ENV === 'production';

Sentry.init({
    dsn: 'https://8b5ed32ed704498eacf862900f0a42eb@o567362.ingest.sentry.io/4505510672203776',
    maxBreadcrumbs: 200,
    sampleRate: 0,
    environment: process.env.NODE_ENV,
    tracesSampleRate: 1,
    release: process.env.REACT_APP_VERSION ?? '1.0.0',
});

const history = createBrowserHistory();

const ClientAsync = lazy(() => import('./routes/'));

const TechnicalWorkPageAsync = lazy(
    () => import('./aqua-delivery-web-client-ui/components/static-pages/TechnicalWorkPage'),
);

const Page404Async = lazy(
    () => import('./aqua-delivery-web-client-ui/components/static-pages/Page404'),
);

I18nSetConfig({ translations });

TokenHelper.restoreTokens();

const App: React.FC = () => {
    const [locale, setLocale] = React.useState(I18n.locale);

    const { googleTagManager } = getAppConfig();

    const { antdLocale } = useAntdLocale();

    const [appParams] = useUrlSearchParams({}, { isAppUpdating: Boolean });

    const isAppUpdating = config.isAppUpdating && appParams.isAppUpdating !== false;

    const { updateDocumentLocale } = useDocumentLanguage();

    const gtmArgs = {
        gtmId: googleTagManager,
    };
    TagManager.initialize(gtmArgs);

    React.useEffect(() => {
        if (locale === localStorage.getItem('language')) {
            updateDocumentLocale(locale);
        } else {
            updateDocumentLocale(navigator.language.split('-')[0]);
        }
    }, [locale, updateDocumentLocale]);

    return (
        // Use require instead of import to initialize redux store before fetching cloud config.
        // Import executes modules asynchronously even before rendering App.tsx, while require executes
        // redux store strictly when App.tsx is being rendering.
        <Sentry.ErrorBoundary fallback={<></>}>
            <ReduxProvider store={require('./redux/store').default}>
                <Seo>
                    <I18nContext.Provider
                        value={{
                            locale,
                            setLocale: locale => {
                                I18nSetConfig({ locale });
                                setLocale(locale);
                            },
                        }}
                    >
                        <YandexMetrika>
                            <LoaderContainer height={window.innerHeight}>
                                <SuspenseWithLoader fallback={<Loader loading={true} />}>
                                    {isAppUpdating ? (
                                        <TechnicalWorkPageAsync />
                                    ) : (
                                        <AntdConfigProvider
                                            locale={antdLocale}
                                            renderEmpty={() => <EmptyPanel />}
                                        >
                                            <Localization.Provider value={localizationConfig}>
                                                <Router history={history}>
                                                    <Switch>
                                                        <Route
                                                            history={history}
                                                            path="/"
                                                            component={ClientAsync}
                                                        />
                                                        <Route component={Page404Async} />
                                                    </Switch>
                                                </Router>
                                            </Localization.Provider>
                                        </AntdConfigProvider>
                                    )}
                                </SuspenseWithLoader>
                            </LoaderContainer>
                        </YandexMetrika>
                    </I18nContext.Provider>
                </Seo>
            </ReduxProvider>
        </Sentry.ErrorBoundary>
    );
};

export default App;

export const AppLoader: React.FC = () => (
    <LoaderContainer height={window.innerHeight}>
        <Loader loading={true} />
    </LoaderContainer>
);
